// Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// main colors
$color-primary: #f9faf1;
$color-secondary: #bd9a68;
$black: #202020;
$gray: #797978;
$white: #fff;

// secondary colors
$green: #00db23;
$lightGreen: rgba($green, 8%);
$yellow: #ffd600;
$lightYellow: rgba($yellow, 8%);
$red: #ef0000;
$lightRed: rgba($red, 8%);

// typography
$text-color: $gray;

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  background-color: $color-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  color: $text-color;
  line-height: 32px;
}

hr {
  background-color: #20202033;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 10px;
  }

  span {
    color: $gray;
    display: inline-block;
    min-width: 150px;
    margin-right: 10px;
  }
}

input,
textarea {
  padding: 20px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid $gray;
}

input {
  height: 52px;
}

textarea {
  height: 156px;
}

/* ELEMENTS */

.heading-big {
  font-family: "Roboto Serif", serif;
  font-weight: 500;
  font-size: 30px;
  color: $black;

  @media screen and (min-width: 900px) {
    font-size: 48px;
  }
}

.heading {
  font-family: "Roboto Serif", serif;
  font-weight: 500;
  font-size: 28px;
  color: $black;

  @media screen and (min-width: 900px) {
    font-size: 42px;
  }
}

/* PADDINGS & MARGINS */

.px-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.px-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.my-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-200 {
  padding-top: 200px;
}

.mb-3 {
  margin-bottom: 32px !important;
}

.mb-4 {
  margin-bottom: 40px !important;
}

.margin-top-4 {
  margin-top: 40px;
}

/* BUTTONS, LABELS & BADGES */

.button {
  font-size: 16px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  padding: 15px 20px;
  border-radius: 4px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border: 1px solid transparent;
  transition: 0.3s;
  cursor: pointer;
}

.button-primary {
  background-color: $color-secondary;
  color: $white;

  &:hover {
    background-color: #794f13;
  }
}

.label {
  display: inline-flex;
  font-weight: 500;
  font-size: 12px;
  padding: 2px 10px;
  text-transform: uppercase;
  color: $color-secondary;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid $color-secondary;
  max-width: fit-content;
}

.badge {
  display: inline-flex;
  align-items: center;
  color: $black;
  font-weight: 500;
  font-size: 12px;
  padding: 4px 10px;
  text-transform: uppercase;
  border-radius: 10px;
  max-width: fit-content;
}

.badge-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}

.badge-green {
  background-color: $lightGreen;
  border: 1px solid $green;

  .badge-circle {
    background-color: $green;
  }
}

.badge-yellow {
  background-color: $lightYellow;
  border: 1px solid $yellow;
  .badge-circle {
    background-color: $yellow;
  }
}

.badge-red {
  background-color: $lightRed;
  border: 1px solid $red;
  .badge-circle {
    background-color: $red;
  }
}

/* BANNER */

.banner-content {
  max-width: 553px;

  @media screen and (min-width: 900px) {
    margin: 100px 0 100px 42px;
  }
}

.banner-title {
  font-family: "Roboto Serif", serif;
  font-size: 42px;
  font-weight: 500;
  color: $black;
  text-transform: uppercase;

  @media screen and (min-width: 900px) {
    font-size: 62px;
  }
}

.banner-subtitle {
  font-size: 16px;
  font-weight: 400;
  color: $text-color;
  line-height: 32px;
}

.banner-heading {
  text-align: center;
  @media screen and (min-width: 900px) {
    margin-right: auto;
    margin-left: auto;
    max-width: 800px;
  }
}

.banner-mobile {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url("../src/assets/adelgam-real-state.webp") center/cover
    no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .banner-title {
    color: $white;
  }

  .banner-subtitle {
    color: $white;
  }

  .banner-content {
    position: relative;
    z-index: 2;
    padding: 20px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, 65%);
  }
}

/* DETAILS */

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;

  i {
    font-size: 48px;
    color: $color-secondary;
  }

  h4 {
    font-family: "Robot serif", "Times New Roman", Times, serif;
    font-size: 18px;
    font-weight: 700;
  }
}

/* NUMBERS */

.numbers {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;

  label {
    font-size: 52px;
    font-weight: 700;
    color: $color-secondary;
  }
}

/* PROJECTS */

.project {
  img {
    object-fit: contain;
    width: 100%;
  }

  h5 {
    font-family: "Roboto Serif", serif;
    font-weight: 500;
    font-size: 24px;
    padding: 40px 0 32px;
    margin: 0;

    @media screen and (min-width: 900px) {
      font-size: 32px;
    }
  }

  .badge {
    margin-bottom: 32px;
  }

  .button {
    margin-top: 32px;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 42px;
  }
}

/* FOOTER */

.footer {
  background-color: $black;
  color: $white;
  margin-top: 120px;
  h6 {
    color: $color-secondary;
    margin-bottom: 16px;
  }

  p,
  a,
  span,
  address {
    font-size: 14px;
    color: $white;
  }

  .copyright p {
    color: $gray;
  }
}

/* NAVBAR */

.navbar-component {
  position: absolute;
  width: 100%;
  background-color: $color-primary;
  z-index: 1030;
  .nav-link {
    color: $black;
  }

  .navbar-toggler {
    border: 2px solid $black;
  }

  @media (max-width: 992px) {
    .navbar-nav {
      align-items: center;
      font-size: 18px;
      padding: 20px 0 20px;
      line-height: 30px;
    }
  }
}

.navbar-component .navbar-toggler:focus {
  box-shadow: none;
}

@media (min-width: 992px) {
  .navbar-component {
    background-color: transparent;
  }
}

// .navbar-collapse {
//   position: fixed;
//   right: 0;
//   padding-left: 15px;
//   padding-right: 15px;
//   padding-bottom: 15px;
//   width: 100%;
//   background: #f1f1f1;
//   max-width: 320px;
//   height: 100vh;
//   overflow-y: auto;
// }

// .navbar-collapse.collapsing {
//   height: 100%;
//   -webkit-transition: right 0.3s ease;
//   -o-transition: right 0.3s ease;
//   -moz-transition: right 0.3s ease;
//   transition: right 0.3s ease;
//   right: -100%;
// }

// .navbar-collapse.show {
//   right: 0;
//   -webkit-transition: right 0.3s ease-in;
//   -o-transition: right 0.3s ease-in;
//   -moz-transition: right 0.3s ease-in;
//   transition: right 0.3s ease-in;
// }

.form-wrapper {
  margin-top: 100px;
  margin-bottom: 60px;

  @media screen and (min-width: 900px) {
    margin-right: auto;
    margin-left: auto;
    max-width: 800px;
  }
}

.projects-heading {
  text-align: center;
  @media screen and (min-width: 900px) {
    margin: 60px auto;
    max-width: 800px;
  }
}

.contacts-wrapper {
  margin-top: 60px;
  margin-bottom: 60px;

  @media screen and (min-width: 900px) {
    margin: 60px auto;
    max-width: 800px;
  }

  h4 {
    font-family: "Roboto Serif", serif;
    font-weight: 500;
    font-size: 24px;
  }

  a {
    color: $text-color;
  }
}

.contacts-map {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;

  @media screen and (min-width: 900px) {
    margin: 60px auto;
    max-width: 700px;
  }
}

.success {
  color: $green;
}

.error {
  color: $red;
}

.hightlights {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;

  label {
    color: $text-color;
    padding-top: 14px;
  }
  span {
    color: $color-secondary;
    font-weight: 600;
    margin-top: 10px;
  }
}

.table {
  border-collapse: separate;
  border-spacing: 0 10px;

  thead th {
    font-size: 14px;
    color: $color-secondary;
    background-color: rgba($color-secondary, 0.2);
    padding: 20px 40px;
  }

  tbody tr td {
    background-color: rgba($color-secondary, 0.08);
    padding: 50px 40px;
    border: none; /* Ensure no borders */
  }

  td {
    vertical-align: middle;
  }

  thead tr th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  thead tr th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  tbody tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  tbody tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.price {
  font-size: 22px;
  font-weight: 500;
  color: $black;
}

// project details div

.project-details-list {
  margin-top: 60px;
}

.project-details-list-title,
.project-details-list-description {
  padding: 15px 40px;
  border-radius: 4px;
}

.project-details-list-title {
  font-weight: 700;
  text-transform: uppercase;
  color: $color-secondary;
  background-color: rgba($color-secondary, 0.2);
  margin-bottom: 10px;
}

.project-details-list-description {
  display: flex;
  align-items: center;
  background-color: rgba($color-secondary, 0.08);

  .project-details-list-images {
    background-color: transparent;
  }

  .project-details-list-images-wrapper {
    flex: 1;
    justify-content: center;
  }

  ul span {
    color: $black;
    min-width: 170px;
  }

  .bullet-list {
    list-style-position: inside;
    list-style-type: disc;
  }
}

.project-details-list-images {
  display: flex;
  justify-content: center;
  background-color: rgba($color-secondary, 0.08);
  padding: 40px 15px;
  gap: 20px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project-details-list-image {
    width: 100%;
    max-width: 350px;
    cursor: pointer;
  }
}

.iframe-title {
  font-family: "Roboto Serif", serif;
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 500px;
  margin: 0 auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

// card component
.card-component {
  border-radius: 4px;
}

.card-component-header {
  font-size: 14px;
  color: $color-secondary;
  background-color: rgba($color-secondary, 0.2);
  padding: 20px 40px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  span {
    color: $color-secondary;
    font-weight: 500;
  }
}

.card-component-body {
  background-color: rgba($color-secondary, 0.08);
  padding: 50px 40px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.deluxe-description {
  margin-top: 60px;
}

.details-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  span {
    color: $color-secondary;
    font-weight: 700;
  }
}

.react-multi-carousel-item div {
  margin: 0 5px;
  @media screen and (min-width: 900px) {
    margin: 0 20px;
  }
}

.separator-home {
  margin-top: 60px;
}
